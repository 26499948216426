<template>
  <div class="row g-7">
    <!--begin::Contact groups-->
    <div class="col-lg-6 col-xl-3">
      <!--begin::Contact group wrapper-->
      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <select
            name="status"
            data-control="select2"
            data-hide-search="true"
            class="form-select form-select-md form-select-solid fw-bolder mb-4"
          >
            <option value="All">Tüm Mülakatlar</option>
            <option value="Active" selected>Teknik Mülakat</option>
            <option value="Approved">İngilizce Mülakat</option>
            <option value="Declined">Star Mülakat</option>
          </select>

          <div class="card-title">
            <h2>Mülakat Sayısı</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-5">
          <!--begin::Contact groups-->
          <div class="d-flex flex-column gap-5">
            <apexchart
              class="statistics-widget-3-chart card-rounded-bottom"
              width="100%"
              type="donut"
              :options="chartOptions"
              :series="series"
            ></apexchart>
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a
                class="
                  fs-6
                  fw-bold
                  text-gray-800 text-hover-primary text-active-primary
                  active
                "
                >Toplam Mülakat</a
              >
              <div class="badge badge-light-primary">11</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Tamamlanan</a
              >
              <div class="badge badge-light-primary">5</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-gray-800 text-hover-primary"
                >Devam Eden</a
              >
              <div class="badge badge-light-primary">4</div>
            </div>
            <!--begin::Contact group-->
            <!--begin::Contact group-->
            <div class="d-flex flex-stack">
              <a class="fs-6 fw-bold text-danger text-hover-primary"
                >İptal Olan</a
              >
              <div class="badge badge-light-danger">2</div>
            </div>
            <!--begin::Contact group-->
          </div>
          <!--end::Contact groups-->
          <!--begin::Separator-->
          <div class="separator my-7"></div>
          <!--begin::Separator-->

          <div class="d-flex w-100 gap-3">
            <router-link
              to="/interview/invite-candidate"
              class="
                btn btn-sm btn-primary
                text-white text-decoration-none
                w-50
                text-center
              "
            >
              Aday Davet Et
            </router-link>

            <router-link
              to="/interview/interview-detail"
              class="
                btn btn-sm btn-secondary btn-active-secondary
                text-decoration-none
                w-50
                text-center
              "
            >
              İncele
            </router-link>
          </div>

          <!-- <router-link to="/interview/interview-detail">
            <a class="btn btn-secondary w-100">
              <i class="ki-duotone ki-badge fs-2"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span
              ></i>
              Mülakat Detayları
            </a>
          </router-link> -->

          <!--begin::Add new contact-->
          <!-- <router-link to="/interview/invite-candidate">
            <a class="btn btn-primary w-100">
              <i class="ki-duotone ki-badge fs-2"
                ><span class="path1"></span><span class="path2"></span
                ><span class="path3"></span><span class="path4"></span
                ><span class="path5"></span
              ></i>
              Mülakat Daveti Gönder
            </a>
          </router-link> -->
          <!--end::Add new contact-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contact group wrapper-->

      <div class="card card-flush mb-7">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_chat_contacts_header">
          <!--begin::Card title-->
          <div class="card-title">
            <h2>Teknik Mülakat</h2>
          </div>
          <!--end::Card title-->
        </div>
        <!--end::Card header-->

        <!--begin::Card body-->
        <div class="card-body pt-0">
          <!--begin::Contact group-->
          <div class="d-flex flex-stack mb-3">
            <a class="fs-6 fw-bold text-gray-800 text-hover-primary">İlan: </a>
            <div class="badge badge-light-info d-inline">
              Hybrid Çalışacak Frontend Developer
            </div>
          </div>

          <p>
            Yaratıcı ve dinamik bir yazılım ekibine katılacak, kullanıcı dostu
            web uygulamaları geliştirmek için güçlü bir frontend developer
            arıyoruz. Çalışma modeli hybrid olacak, ofis ve uzaktan çalışma
            fırsatı sunulmaktadır.
          </p>
          <a class="fs-6 fw-bold text-gray-800 mt-3">Teklif Edilen İmkanlar</a>
          <ul>
            <li>Hybrid çalışma modeli (ofis ve uzaktan çalışma seçeneği)</li>
            <li>Esnek çalışma saatleri</li>
            <li>Yaratıcı ve destekleyici bir çalışma ortamı</li>
            <li>
              Yeteneklerinizi geliştirmek için eğitim ve gelişim fırsatları
            </li>
          </ul>

          <!--begin::Contact group-->
          <!--begin::Permissions-->
          <a class="fs-6 fw-bold text-gray-800 text-hover-primary mt-3"
            >İlan Gereksinimleri:
          </a>
          <div class="d-flex flex-column text-gray-600">
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Minimum 3 yıl iş
              tecrübesi
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> İlgili mühendislik
              bölümlerinden mezun
            </div>
            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span> Vuejs, Typescript ile
              tecrübesi olan
            </div>

            <div class="d-flex align-items-center py-2">
              <span class="bullet bg-primary me-3"></span>
              <em>9 adet daha..</em>
            </div>
          </div>
          <!--end::Permissions-->
        </div>
        <!--end::Card body-->

        <!--begin::Card footer-->
        <div class="card-footer pt-0">
          <button
            type="button"
            class="btn btn-light btn-active-primary w-100"
            data-bs-toggle="modal"
            data-bs-target="#kt_modal_update_role"
          >
            İlanı Düzenle
          </button>
        </div>
        <!--end::Card footer-->
      </div>
      <!--begin::Modal-->
      <div
        class="modal fade"
        id="kt_modal_update_role"
        tabindex="-1"
        aria-hidden="true"
      >
        <!--begin::Modal dialog-->
        <div class="modal-dialog modal-dialog-centered mw-750px">
          <!--begin::Modal content-->
          <div class="modal-content">
            <!--begin::Modal header-->
            <div class="modal-header">
              <!--begin::Modal title-->
              <h2 class="fw-bold">İlanı Düzenle</h2>
              <!--end::Modal title-->

              <!--begin::Close-->
              <div
                class="btn btn-icon btn-sm btn-active-icon-primary"
                data-kt-roles-modal-action="close"
              >
                <i class="ki-duotone ki-cross fs-1"
                  ><span class="path1"></span><span class="path2"></span
                ></i>
              </div>
              <!--end::Close-->
            </div>
            <!--end::Modal header-->

            <!--begin::Modal body-->
            <div class="modal-body scroll-y mx-5 my-7">
              <!--begin::Form-->
              <form
                id="kt_modal_update_role_form"
                class="form fv-plugins-bootstrap5 fv-plugins-framework"
                action="#"
              >
                <!--begin::Scroll-->
                <div
                  class="d-flex flex-column scroll-y me-n7 pe-7"
                  id="kt_modal_update_role_scroll"
                  data-kt-scroll="true"
                  data-kt-scroll-activate="{default: false, lg: true}"
                  data-kt-scroll-max-height="auto"
                  data-kt-scroll-dependencies="#kt_modal_update_role_header"
                  data-kt-scroll-wrappers="#kt_modal_update_role_scroll"
                  data-kt-scroll-offset="300px"
                  style="max-height: 541px"
                >
                  <!--begin::Input group-->
                  <div class="fv-row mb-10 fv-plugins-icon-container">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2">
                      <span class="required">İlan Başlığı</span>
                    </label>
                    <!--end::Label-->

                    <!--begin::Input-->
                    <input
                      class="form-control form-control-solid"
                      placeholder="Pozisyon adı girin"
                      name="role_name"
                      value="Frontend Developer"
                    />
                    <!--end::Input-->
                    <div
                      class="
                        fv-plugins-message-container
                        fv-plugins-message-container--enabled
                        invalid-feedback
                      "
                    ></div>
                  </div>
                  <!--end::Input group-->

                  <div class="fv-row mb-5">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2"
                      >İlan Açıklaması</label
                    >
                    <!--end::Label-->

                    <vue-editor
                      v-model="positionDesc"
                      class="form-control form-control-solid"
                      style="padding: 0px"
                    ></vue-editor>
                  </div>

                  <div class="row mt-4">
                    <!-- Başlangıç Tarihi -->
                    <div class="col-md-6">
                      <div class="fv-row mb-10">
                        <label
                          class="d-flex align-items-center form-label mb-5"
                          >{{ $t("modals.interview.startDate") }}</label
                        >
                        <div class="position-relative align-items-center">
                          <Field
                            type="date"
                            name="startDate"
                            class="
                              form-control form-control-lg form-control-solid
                            "
                            :placeholder="$t('modals.interview.enterStartDate')"
                            :min="today"
                            v-model="startDate"
                          />
                        </div>
                      </div>
                    </div>

                    <!-- Bitiş Tarihi -->
                    <div class="col-md-6 fv-row">
                      <label
                        class="d-flex align-items-center form-label mb-5"
                        >{{ $t("modals.interview.endDate") }}</label
                      >
                      <div class="position-relative align-items-center">
                        <Field
                          type="date"
                          name="endDate"
                          class="
                            form-control form-control-lg form-control-solid
                          "
                          :placeholder="$t('modals.interview.enterEndDate')"
                          :min="startDate"
                          v-model="endDate"
                        />
                      </div>
                    </div>
                  </div>

                  <!--begin::Permissions-->
                  <div class="fv-row mb-5">
                    <!--begin::Label-->
                    <label class="fs-5 fw-bold form-label mb-2"
                      >Aranılan Özellikler</label
                    >
                    <!--end::Label-->

                    <!--begin::Input group-->
                    <div class="mb-6 fv-row">
                      <!--begin::Input-->
                      <input
                        type="text"
                        class="form-control form-control-lg form-control-solid"
                        v-model="featureInput"
                        @keyup.enter="addFeature"
                        placeholder="Özellik ekleyin ve Enter'a basın"
                        v-on:keydown.enter.prevent
                      />
                      <!--end::Input-->
                    </div>
                    <!--end::Input group-->

                    <!--begin::Feature List-->
                    <div v-if="features.length" class="card-body pt-0">
                      <div class="d-flex flex-column text-gray-600">
                        <div
                          v-for="(feature, index) in features"
                          :key="index"
                          class="d-flex align-items-center py-2"
                        >
                          <span class="bullet bg-primary me-3"></span>
                          {{ feature }}
                        </div>
                      </div>
                    </div>
                    <!--end::Feature List-->
                  </div>
                  <!--end::Permissions-->

                  <div class="fv-row mb-5">
                    <div class="w-100" @dragover.prevent="preventScroll">
                      <!--begin::Heading-->
                      <label class="fs-5 fw-bold form-label mb-1"
                        >Mülakatları Sırala</label
                      >

                      <div class="text-gray-400 fw-bold fs-8 mb-3">
                        Mülakatların öncelik sırasını belirle.
                      </div>

                      <!-- Mülakatları sıralama alanı -->
                      <div class="d-grid gap-4 grid-columns-4">
                        <div
                          v-for="(interview, index) in interviews"
                          :key="interview.id"
                          class="interview-card"
                          :class="{
                            dragging: index === draggedIndex,
                            'drag-over': index === dragOverIndex,
                          }"
                          draggable="true"
                          @dragstart="onDragStart(index)"
                          @dragover.prevent="onDragOver(index)"
                          @dragleave="onDragLeave"
                          @dragend="onDragEnd"
                          @drop="onDrop(index)"
                        >
                          <div class="interview-content">
                            <div class="interview-order">{{ index + 1 }}</div>
                            <h3 class="fw-bold">{{ interview.title }}</h3>
                            <p class="text-gray-500">
                              {{ interview.description }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!--end::Scroll-->

                <!--begin::Actions-->
                <div class="text-center pt-15">
                  <button
                    type="reset"
                    class="btn btn-light me-3"
                    data-bs-dismiss="modal"
                  >
                    Vazgeç
                  </button>

                  <button
                    type="submit"
                    class="btn btn-primary"
                    data-kt-roles-modal-action="submit"
                  >
                    <span class="indicator-label"> Güncelle </span>
                    <span class="indicator-progress">
                      Lütfen bekleyiniz...
                      <span
                        class="
                          spinner-border spinner-border-sm
                          align-middle
                          ms-2
                        "
                      ></span>
                    </span>
                  </button>
                </div>
                <!--end::Actions-->
              </form>
              <!--end::Form-->
            </div>
            <!--end::Modal body-->
          </div>
          <!--end::Modal content-->
        </div>
        <!--end::Modal dialog-->
      </div>
      <!--end::Modal-->
    </div>
    <!--end::Contact groups-->

    <!--begin::Search-->
    <div class="col-lg-6 col-xl-3">
      <!--begin::Contacts-->
      <div class="card card-flush" id="kt_contacts_list">
        <!--begin::Card header-->
        <div class="card-header pt-7" id="kt_contacts_list_header">
          <!--begin::Form-->
          <form
            class="d-flex align-items-center position-relative w-100 m-0"
            autocomplete="off"
          >
            <!--begin::Input-->
            <input
              type="text"
              class="form-control form-control-solid ps-13"
              name="search"
              value=""
              placeholder="Aday Ara"
            />
            <!--end::Input-->
          </form>
          <!--end::Form-->
        </div>
        <!--end::Card header-->
        <!--begin::Card body-->
        <div class="card-body pt-0" id="kt_contacts_list_body">
          <table
            class="
              table table-row-dashed table-row-gray-300
              align-middle
              gs-0
              gy-4
            "
          >
            <thead>
              <tr class="fw-bolder text-muted text-center">
                <div
                  class="fw-bold fs-6 mt-3"
                  style="
                    cursor: pointer;
                    color: #000;
                    display: inline-flex;
                    align-items: center;
                  "
                  @click="toggleArrow"
                >
                  Pozisyon Uygunluğu
                  <!-- Dinamik olarak ok yönünü değiştiren SVG iconlar -->
                  <span
                    class="svg-icon svg-icon-3 svg-icon-danger"
                    v-if="!arrowDirection"
                    style="margin-left: 5px"
                  >
                    <inline-svg src="/media/icons/duotune/arrows/arr065.svg" />
                  </span>
                  <span
                    class="svg-icon svg-icon-3 svg-icon-success"
                    v-else
                    style="margin-left: 5px"
                  >
                    <inline-svg src="/media/icons/duotune/arrows/arr066.svg" />
                  </span>
                </div>
              </tr>
            </thead>
          </table>

          <!--begin::List-->
          <div
            class="scroll-y me-n5 pe-5 h-300px h-xl-auto"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_header, #kt_toolbar, #kt_footer, #kt_contacts_list_header"
            data-kt-scroll-wrappers="#kt_content, #kt_contacts_list_body"
            data-kt-scroll-stretch="#kt_contacts_list, #kt_contacts_main"
            data-kt-scroll-offset="5px"
            style="max-height: 1250px"
          >
            <!--begin::User-->
            <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >B</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Buse Çallı</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    busecalli25@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-success">97%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >M</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a
                    href="/metronic8/demo1/apps/contacts/view-contact.html"
                    class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Melodi Cansın</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    melody@altbox.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-success">92%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-primary
                      text-primary
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >M</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Mesut Gürses</a
                  >
                  <div class="fw-semibold fs-7 text-muted">mesut@ogza.com</div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-warning">49%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->

            <!--begin::User-->
            <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-primary
                      text-primary
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >S</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Sacit Gürsoy</a
                  >
                  <div class="fw-semibold fs-7 text-muted">sacit@ogza.com</div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-danger">20%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->


                        <!--begin::User-->
                        <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >B</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Beril Sergün</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    beril@sergun.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-warning">75%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->



                                    <!--begin::User-->
                                    <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >R</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Rüveyda Açıkalın</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    ruveyda@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-warning">46%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->



               <!--begin::User-->
               <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >F</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Fatma Turgut</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    f.turgut@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-danger">38%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->


                           <!--begin::User-->
                           <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-primary
                      text-primary
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >T</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Tuğberk Gürsoy</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    tugberk@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-danger">30%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->

            <!--begin::Separator-->
            <div class="separator separator-dashed d-none"></div>
            <!--end::Separator-->


            
                           <!--begin::User-->
                           <div
              class="d-flex py-4 contact-item"
              style="display: table; width: 100%"
            >
              <!--begin::Details (Aday Bilgileri)-->
              <div
                class="d-flex align-items-center"
                style="display: table-cell; width: 80%"
              >
                <!--begin::Avatar-->
                <div class="symbol symbol-40px symbol-circle">
                  <span
                    class="
                      symbol-label
                      bg-light-danger
                      text-danger
                      fs-6
                      ms-3
                      fw-bolder
                    "
                    >T</span
                  >
                </div>
                <!--end::Avatar-->
                <!--begin::Details-->
                <div class="ms-4">
                  <a class="fs-6 fw-bold text-gray-900 text-hover-primary mb-2"
                    >Nalan Pekgür</a
                  >
                  <div class="fw-semibold fs-7 text-muted">
                    nalan.pk@gmail.com
                  </div>
                </div>
                <!--end::Details-->
              </div>
              <!--end::Details-->

              <!--begin::Puan (Puan Durumu)-->
              <div style="display: table-cell; width: 20%; text-align: right">
                <span class="badge badge-light-success">90%</span>
              </div>
              <!--end::Puan-->
            </div>
            <!--end::User-->
          </div>
          <!--end::List-->
        </div>
        <!--end::Card body-->
      </div>
      <!--end::Contacts-->
    </div>
    <!--end::Search-->

    <!--begin::Content-->
    <div class="col-xl-6">
      <!--begin::Content-->
      <div class="flex-lg-row-fluid">
        <!--begin:::Tabs-->
        <ul
          class="
            nav nav-custom nav-tabs nav-line-tabs nav-line-tabs-2x
            border-0
            fs-4
            fw-semibold
            mb-8
          "
          role="tablist"
        >
          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4 active"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_tab"
              aria-selected="true"
              role="tab"
              >Aday Detayları</a
            >
          </li>
          <!--end:::Tab item-->

          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_events_and_logs_tab"
              aria-selected="false"
              role="tab"
              tabindex="-1"
              >Mülakat Cevapları</a
            >
          </li>
          <!--end:::Tab item-->

          <!--begin:::Tab item-->
          <li class="nav-item" role="presentation">
            <a
              class="nav-link text-active-primary pb-4"
              data-kt-countup-tabs="true"
              data-bs-toggle="tab"
              href="#kt_customer_view_overview_statements"
              data-kt-initialized="1"
              aria-selected="false"
              role="tab"
              tabindex="-1"
              >Mülakat Logları</a
            >
          </li>
          <!--end:::Tab item-->
        </ul>
        <!--end:::Tabs-->

        <!--begin:::Tab content-->
        <div class="tab-content" id="myTabContent">
          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade active show"
            id="kt_customer_view_overview_tab"
            role="tabpanel"
          >
            <!--begin::Contacts-->
            <div class="card card-flush h-lg-100" id="kt_contacts_main">
              <!--begin::Card header-->
              <div class="card-header pt-3" id="kt_chat_contacts_header">
                <!--begin::Card title-->
                <div class="card-title">
                  <i class="ki-duotone ki-badge fs-1"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span
                  ></i>
                  <h2>Aday Detayları</h2>
                </div>
                <div class="card-toolbar gap-3">
                  <span class="badge badge-light-success"
                    >Süreç Tamamlandı</span
                  >
                  <span class="badge badge-light-danger">Başarısız</span>
                </div>
                <!--end::Card title-->
              </div>
              <!--end::Card header-->

              <!--begin::Card body-->
              <div class="card-body pt-5">
                <!--begin::Profile-->
                <div class="d-flex gap-7 align-items-center">
                  <router-link to="/interview/candidate-detail">
                    <div class="symbol symbol-100px symbol-circle mb-2">
                      <!--begin:: Avatar -->
                      <div
                        class="
                          symbol symbol-circle symbol-50px
                          overflow-hidden
                          me-1
                        "
                      >
                        <a
                          ><div
                            class="
                              symbol-label
                              fs-1
                              bg-light-danger
                              text-danger
                            "
                          >
                            B
                          </div></a
                        >
                      </div>
                      <!--end::Avatar-->
                    </div>
                  </router-link>
                  <!--begin::Contact details-->
                  <div class="d-flex flex-column gap-2">
                    <!--begin::Name-->
                    <router-link to="/interview/candidate-detail">
                      <h2 class="mb-0 text-hover-primary">Buse Çallı</h2>
                    </router-link>
                    <!--end::Name-->

                    <!--begin::Email-->
                    <div class="d-flex">
                      <i class="ki-duotone ki-sms fs-2"
                        ><span class="path1"></span><span class="path2"></span
                      ></i>
                      <a href="#" class="text-muted text-hover-primary"
                        >busecalli25@gmail.com</a
                      >
                    </div>
                    <!--end::Email-->

                    <!--begin::Phone-->
                    <div class="d-flex">
                      <i class="ki-duotone ki-phone fs-2"
                        ><span class="path1"></span><span class="path2"></span
                      ></i>
                      <a href="#" class="text-muted text-hover-primary"
                        >+905359807641</a
                      >
                    </div>
                    <!--end::Phone-->
                  </div>

                  <div class="d-flex flex-wrap flex-center">
                    <!--begin::Stats-->
                    <div
                      class="
                        border border-gray-300 border-dashed
                        rounded
                        py-3
                        px-3
                        mb-3
                      "
                    >
                      <div class="fs-4 fw-bold text-gray-700">
                        <span class="w-75px">97%</span>
                        <i class="ki-duotone ki-arrow-up fs-3 text-success"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </div>
                      <div class="fw-semibold text-muted">
                        Pozisyon Uygunluğu
                      </div>
                    </div>
                    <!--end::Stats-->
                    <!--begin::Stats-->
                    <div
                      class="
                        border border-gray-300 border-dashed
                        rounded
                        py-3
                        px-3
                        mb-3
                        ms-3
                      "
                    >
                      <div class="fs-4 fw-bold text-gray-700">
                        <span class="w-75px">90%</span>
                        <i class="ki-duotone ki-arrow-up fs-3 text-success"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </div>
                      <div class="fw-semibold text-muted">CV Analizi</div>
                    </div>
                    <!--end::Stats-->
                  </div>
                  <!--end::Contact details-->
                </div>

                <!--end::Profile-->

                <div class="d-flex flex-column gap-5 mt-7">
                  <!--begin::Company name-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Mezun Olduğu Bölüm</div>
                    <div class="fw-bold fs-6">Yazılım Mühendisliği</div>
                  </div>
                  <!--end::Company name-->
                  <!--begin::Company name-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Mezun Olduğu Okul</div>
                    <div class="fw-bold fs-6">Doğuş Üniversitesi</div>
                  </div>
                  <!--end::Company name-->

                  <!--begin::City-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">İş Tecrübesi</div>
                    <div class="fw-bold fs-6">2 yıl</div>
                  </div>
                  <!--end::City-->

                  <!--begin::Country-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Yabancı Dil</div>
                    <div class="fw-bold fs-6">İngilizce</div>
                  </div>
                  <!--end::Country-->

                  <!--begin::Notes-->
                  <div class="d-flex flex-column gap-1">
                    <div class="fw-bold text-muted">Adres</div>
                    <div class="fw-bold fs-6">
                      Cevatpaşa Mahallesi Çalışkan Sokak No 14 Daire 7,
                      Istanbul, Turkiye
                    </div>
                  </div>
                  <!--end::Notes-->
                </div>
                <!--end::Additional details-->
              </div>
              <!--end::Card body-->
            </div>
            <!--end::Contacts-->
            <!--begin::Earnings-->
            <div class="card mb-4 mb-xl-9 mt-7">
              <!--begin::Header-->
              <div class="card-header border-0">
                <div class="card-title pt-7">
                  <h2>Mülakat Analizi</h2>
                </div>
                <div class="fs-7 fw-semibold text-gray-500">
                  Adayın mülakat deneyimi ile yapay zekanın analizi ve ilgili
                  pozisyona uygunluk oranı
                </div>
              </div>
              <!--end::Header-->

              <!--begin::Body-->
              <div class="card-body py-0">
                <div class="separator my-7"></div>
                <div class="fw-bold fs-6">Teknik Mülakat Analizi</div>

                <!--begin::Left Section-->
                <div class="d-flex flex-wrap flex-stack mb-5">
                  <!--begin::Row-->
                  <div class="d-flex flex-wrap">
                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-125px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          class="counted"
                          data-kt-countup="true"
                          data-kt-countup-value="16"
                          data-kt-initialized="1"
                        ></span
                        >85%
                        <i class="ki-duotone ki-arrow-down fs-1 text-danger"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >Mülakat Başarısı</span
                      >
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-150px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          data-kt-countup="true"
                          data-kt-countup-value="1,240"
                          data-kt-countup-prefix="$"
                          class="counted"
                          data-kt-initialized="1"
                          >95%</span
                        >
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >Duygu Analizi</span
                      >
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                </div>
                <!--end::Left Section-->

                <div class="separator my-7"></div>

                <div class="fw-bold fs-6">İngilizce Mülakat Analizi</div>

                <!--begin::Left Section-->
                <div class="d-flex flex-wrap flex-stack mb-5">
                  <!--begin::Row-->
                  <div class="d-flex flex-wrap">
                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-125px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          class="counted"
                          data-kt-countup="true"
                          data-kt-countup-value="16"
                          data-kt-initialized="1"
                        ></span
                        >90%
                        <i class="ki-duotone ki-arrow-down fs-1 text-danger"
                          ><span class="path1"></span><span class="path2"></span
                        ></i>
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >Mülakat Başarısı</span
                      >
                    </div>
                    <!--end::Col-->

                    <!--begin::Col-->
                    <div
                      class="
                        border border-dashed border-gray-300
                        w-150px
                        rounded
                        my-3
                        p-4
                        me-6
                      "
                    >
                      <span class="fs-1 fw-bold text-gray-800 lh-1">
                        <span
                          data-kt-countup="true"
                          data-kt-countup-value="1,240"
                          data-kt-countup-prefix="$"
                          class="counted"
                          data-kt-initialized="1"
                          >90%</span
                        >
                      </span>
                      <span
                        class="fs-6 fw-semibold text-muted d-block lh-1 pt-2"
                        >Duygu Analizi</span
                      >
                    </div>
                    <!--end::Col-->
                  </div>
                  <!--end::Row-->
                </div>
                <!--end::Left Section-->
              </div>
              <!--end::Body-->
            </div>
            <!--end::Earnings-->
          </div>
          <!--end:::Tab pane-->

          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade"
            id="kt_customer_view_overview_events_and_logs_tab"
            role="tabpanel"
          >
            <div class="card card-flush h-lg-100" id="kt_contacts_main">
              <!--begin::Card header-->
              <div class="card-header" id="kt_chat_contacts_header">
                <!--begin::Card title-->
                <div class="card-title pt-3">
                  <i class="ki-duotone ki-badge fs-1"
                    ><span class="path1"></span><span class="path2"></span
                    ><span class="path3"></span><span class="path4"></span
                    ><span class="path5"></span
                  ></i>
                  <h2>Mülakat Cevapları</h2>
                </div>
                <!--begin::Controls-->
                <div class="d-flex flex-wrap mt-6">
                  <!--begin::Select wrapper-->
                  <div class="m-0">
                    <!--begin::Select-->
                    <select
                      name="status"
                      data-control="select2"
                      data-hide-search="true"
                      class="
                        form-select form-select-white form-select-sm
                        fw-bolder
                        w-175px
                      "
                    >
                      <option value="Active" selected>Teknik Mülakat</option>
                      <option value="Approved">İngilizce Mülakat</option>
                      <option value="Declined">Star Mülakat</option>
                    </select>
                    <!--end::Select-->
                  </div>
                  <!--end::Select wrapper-->
                </div>
                <!--end::Controls-->
                <!--end::Card title-->
              </div>
              <!--end::Card header-->

              <!--begin::Card body-->
              <div
                class="card-body pt-5 mb-7"
                style="max-height: 700px; overflow: auto"
              >
                <div class="d-flex flex-column gap-5">
                  <!--begin::Question 1-->
                  <div class="question-card">
                    <div class="question-title">
                      React ile ne kadar süredir çalışıyorsunuz ve
                      projelerinizde hangi React özelliklerini kullanıyorsunuz?
                    </div>
                    <div class="question-answer">
                      3 yıldır React ile çalışıyorum. Hooks, Context API ve
                      Redux gibi state yönetim araçlarını kullanıyorum. Ayrıca
                      TypeScript ile projeler geliştiriyorum.
                    </div>
                  </div>
                  <!--end::Question 1-->

                  <!--begin::Question 2-->
                  <div class="question-card">
                    <div class="question-title">
                      React projelerinizde performans optimizasyonlarını nasıl
                      sağlıyorsunuz?
                    </div>
                    <div class="question-answer">
                      React.memo, useMemo, useCallback gibi yöntemleri
                      kullanıyorum. Ayrıca, ağır işlemleri arka planda
                      çalıştırmak için Web Workers kullanıyorum ve Lighthouse
                      ile performans testleri yapıyorum.
                    </div>
                  </div>
                  <!--end::Question 2-->

                  <!--begin::Question 3-->
                  <div class="question-card">
                    <div class="question-title">
                      Projelerinizde hangi state yönetim kütüphanesini tercih
                      ediyorsunuz ve neden?
                    </div>
                    <div class="question-answer">
                      Küçük projelerde Context API ve React Query kullanıyorum.
                      Büyük projelerde Redux Toolkit veya Zustand tercih
                      ediyorum çünkü güçlü bir ekosistem sunuyor ve asenkron
                      işlemleri yönetmek daha kolay.
                    </div>
                  </div>
                  <!--end::Question 3-->

                  <!--begin::Question 4-->
                  <div class="question-card">
                    <div class="question-title">
                      React ile geliştirdiğiniz projelerde karşılaştığınız en
                      büyük zorluk neydi ve bu zorluğu nasıl aştınız?
                    </div>
                    <div class="question-answer">
                      En büyük zorluklardan biri, büyük bir projede bileşenlerin
                      yeniden render edilmesini optimize etmekti. Bu sorunu
                      çözmek için React Profiler ile performans ölçümleri yaptım
                      ve gereksiz render’ları önlemek için memoization
                      tekniklerini uyguladım.
                    </div>
                  </div>
                  <!--end::Question 4-->

                  <!--begin::Question 5-->
                  <div class="question-card">
                    <div class="question-title">
                      Takım içinde kod kalitesini nasıl sağlıyorsunuz ve kod
                      incelemelerinde nelere dikkat edersiniz?
                    </div>
                    <div class="question-answer">
                      Kod kalitesini sağlamak için ESLint ve Prettier
                      kullanıyoruz. Ayrıca, CI/CD süreçlerinde otomatik testler
                      çalıştırıyoruz. Kod incelemelerinde okunabilirliğe,
                      sürdürülebilirliğe ve performansa özellikle dikkat
                      ediyorum.
                    </div>
                  </div>
                  <!--end::Question 5-->
                </div>
              </div>

              <!--end::Card body-->
            </div>
          </div>
          <!--end:::Tab pane-->

          <!--begin:::Tab pane-->
          <div
            class="tab-pane fade"
            id="kt_customer_view_overview_statements"
            role="tabpanel"
          >
            <!--begin::Statements-->
            <div class="card mb-6 mb-xl-9">
              <!--begin::Header-->
              <div class="card-header">
                <!--begin::Title-->
                <div class="card-title pt-3">
                  <h2>Mülakat Logları</h2>
                </div>
                <!--end::Title-->
                <!--begin::Controls-->
                <div class="d-flex flex-wrap mt-6">
                  <!--begin::Select wrapper-->
                  <div class="m-0">
                    <!--begin::Select-->
                    <select
                      name="status"
                      data-control="select2"
                      data-hide-search="true"
                      class="
                        form-select form-select-white form-select-sm
                        fw-bolder
                        w-175px
                      "
                    >
                      <option value="Active" selected>Teknik Mülakat</option>
                      <option value="Approved">İngilizce Mülakat</option>
                      <option value="Declined">Star Mülakat</option>
                    </select>
                    <!--end::Select-->
                  </div>
                  <!--end::Select wrapper-->
                </div>
                <!--end::Controls-->
              </div>
              <!--end::Header-->

              <!--begin::Card body-->
              <div
                class="card-body pb-5 mb-7"
                style="max-height: 700px; overflow: auto"
              >
                <div class="row mt-5">
                  <!-- Soru 1 -->
                  <MessageIn
                    ref="messagesInRef"
                    :text="'React ile çalışırken karşılaştığınız en büyük zorluk neydi ve bu zorluğu nasıl çözdünüz?'"
                    :id="1"
                  ></MessageIn>

                  <MessageOut
                    ref="messagesOutRef"
                    :text="'En büyük zorluklardan biri performans optimizasyonuydu. Büyük bir projede gereksiz render’ları azaltmak için React.memo ve useMemo kullandım. Ayrıca, Redux performansını optimize etmek için lazy-loading ve dynamic import tekniklerini uyguladım.'"
                    :id="1"
                  ></MessageOut>

                  <!-- Soru 2 -->
                  <MessageIn
                    ref="messagesInRef"
                    :text="'State yönetimi için hangi kütüphaneleri kullanıyorsunuz ve neden tercih ediyorsunuz?'"
                    :id="2"
                  ></MessageIn>

                  <MessageOut
                    ref="messagesOutRef"
                    :text="'Küçük projelerde Context API ve React Query kullanıyorum. Büyük projelerde ise Redux Toolkit veya Zustand tercih ediyorum çünkü güçlü bir ekosistem sunuyor ve asenkron işlemleri yönetmek daha kolay.'"
                    :id="2"
                  ></MessageOut>

                  <!-- Soru 3 -->
                  <MessageIn
                    ref="messagesInRef"
                    :text="'React projelerinde performans optimizasyonlarını nasıl sağlıyorsunuz?'"
                    :id="3"
                  ></MessageIn>

                  <MessageOut
                    ref="messagesOutRef"
                    :text="'React Profiler ile performans ölçümleri yapıyorum. useMemo, useCallback ve React.lazy gibi tekniklerle bileşen yükünü optimize ediyorum. Ayrıca Lighthouse kullanarak performansı sürekli izliyorum.'"
                    :id="3"
                  ></MessageOut>

                  <!-- Soru 4 -->
                  <MessageIn
                    ref="messagesInRef"
                    :text="'Takım çalışmasında kod kalitesini nasıl sağlıyorsunuz?'"
                    :id="4"
                  ></MessageIn>

                  <MessageOut
                    ref="messagesOutRef"
                    :text="'Kod kalitesini sağlamak için ESLint, Prettier ve Jest kullanıyoruz. Ayrıca her kod incelemesinde okunabilirlik, performans ve sürdürülebilirlik kriterlerine dikkat ediyorum.'"
                    :id="4"
                  ></MessageOut>
                </div>
              </div>

              <!-- <div
                class="card-body pb-5 mb-7"
                style="max-height: 700px; overflow: auto"
              >
                <div
                  class="row mt-5"
                  v-for="(log, index) in pollChatLogs"
                  :key="index"
                >
                  <MessageIn
                    v-if="log.poll.owner == 'AI'"
                    ref="messagesInRef"
                    :text="log.poll.text"
                    :id="log.poll.id"
                    :voice="log.poll.voice"
                  ></MessageIn>
                  <MessageOut
                    v-else
                    ref="messagesOutRef"
                    :text="log.poll.text"
                    :voice="log.poll.voice"
                    :video="log.poll.video"
                    :id="log.poll.id"
                  ></MessageOut>
                </div>
              </div> -->
              <!--end::Card body-->
            </div>
            <!--end::Statements-->
          </div>
          <!--end:::Tab pane-->
        </div>
        <!--end:::Tab content-->
      </div>
      <!--end::Content-->
    </div>
    <!--end::Content-->
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
    VueEditor,
  },
  computed: {},
  setup() {
    const today = new Date().toISOString().split("T")[0];
    const startDate = ref(today);
    const endDate = ref("");

    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const router = useRouter();
    const arrowDirection = ref<boolean>(false); // false = 'down', true = 'up'

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: ["Tamamlanan", "Devam Eden", "İptal Olan"],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = [5, 4, 2];

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const interviews = ref([
      {
        id: 1,
        title: "Teknik Mülakat",
        description: "Bu mülakat adayın teknik bilgilerini ölçer.",
      },
      {
        id: 2,
        title: "Yabancı Dil Mülakatı",
        description: "Bu mülakat adayın yabancı dil seviyesini ölçer.",
      },
      {
        id: 3,
        title: "Yazılım Test Mülakatı",
        description: "Bu mülakat adayın test becerilerini ölçer.",
      },
      {
        id: 4,
        title: "Star Mülakat",
        description:
          "Bu mülakat adayın deneyimlerini ve kariyerine olan bakış açısını ölçer.",
      },
    ]);

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      questionTypeLabel,
      isLoading,
      positionName,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      positionDesc,
      chartOptions,
      series,
      arrowDirection,
      today,
      startDate,
      endDate,
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
    };
  },
  methods: {
    toggleArrow() {
      this.arrowDirection = !this.arrowDirection;
    },
  },
});
</script>

<style>
.contact-item:hover {
  background-color: #f8f0f0; /* Gri renk */
  cursor: pointer; /* Tıklanabilir işaretçi */
}

/* İsteğe bağlı olarak hover'da font rengini değiştirme */
.contact-item:hover .fs-6 {
  color: #007bff; /* Örneğin, mavi renk */
}

.question-card {
  border: 1px solid #e9e9e9;
  padding: 14px;
  margin-bottom: 10px;
  border-radius: 8px;
  background-color: #fafafa;
}

.question-title {
  font-weight: bold;
  font-size: 1rem;
  color: #6c757d;
}

.question-answer {
  font-size: 0.875rem;
  color: #495057;
  margin-top: 10px;
}

/* Grid Layout */
.d-grid {
  display: grid;
  gap: 16px;
}

.grid-columns-4 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.interview-card {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  box-sizing: border-box;
  transition: none; /* Geçiş animasyonunu iptal et */
}

/* Sürükleme Sırasında Stil */
.dragging {
  opacity: 0.5;
}

.drag-over {
  outline: 2px dashed #007bff; /* Border yerine outline kullanıldı */
  background-color: #e2e6ea;
}

/* Kart içerisindeki içerik */
.interview-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

/* Sıra İbaresi */
.interview-order {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
