
import { defineComponent, onMounted, ref } from "vue";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { useRoute, useRouter } from "vue-router";
import ResponseIn from "@/presentation/components/pollster-response/ResponseIn.vue";
import ResponseOut from "@/presentation/components/pollster-response/ResponseOut.vue";
import MessageIn from "@/presentation/components/chat-log/MessageIn.vue";
import MessageOut from "@/presentation/components/chat-log/MessageOut.vue";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { PollResponseListModel } from "@/domain/pollster/poll-statistic/poll-response-list/model/PollResponseListModel";
import { PollMessageListModel } from "@/domain/pollster/poll-statistic/poll-message-list/model/PollMessageListModel";
import { VueEditor, Quill } from "vue3-editor";

export default defineComponent({
  name: "Interview-Detail",
  components: {
    Field,
    Form,
    ErrorMessage,
    ResponseIn,
    ResponseOut,
    MessageIn,
    MessageOut,
    VueEditor,
  },
  computed: {},
  setup() {
    const today = new Date().toISOString().split("T")[0];
    const startDate = ref(today);
    const endDate = ref("");

    const { t } = useI18n();
    const isLoading = ref<boolean>(false);
    const positionName = ref("");
    const positionDesc = ref("");
    const featureInput = ref("");
    const features = ref<string[]>([]);
    const router = useRouter();
    const arrowDirection = ref<boolean>(false); // false = 'down', true = 'up'

    const chartOptions = {
      chart: {
        type: "donut",
      },
      labels: ["Tamamlanan", "Devam Eden", "İptal Olan"],
      legend: {
        show: false, // Yandaki yazıları gizler
      },
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              value: {
                show: true,
                fontSize: "14px", // Ortadaki toplam değer boyutu
                fontWeight: "bold",
                color: "#333",
              },
              total: {
                show: true,
                label: "Toplam",
                formatter: function (w) {
                  return w.globals.seriesTotals.reduce((a, b) => a + b, 0);
                },
                fontSize: "15px",
                fontWeight: "bold",
                color: "#333",
              },
            },
          },
        },
      },
    };

    const series = [5, 4, 2];

    const questionTypeLabel = (value?: string): string => {
      switch (value?.toString()) {
        case "OPEN_ENDED":
          return "Açık Uçlu";
        case "SINGLE_CHOICE":
          return "Tek Seçenekli";
        case "MULTIPLE_CHOICE":
          return "Çok Seçenekli";
        default:
          return "";
      }
    };

    const addFeature = () => {
      if (featureInput.value.trim()) {
        features.value.push(featureInput.value.trim());
        featureInput.value = ""; // Input alanını temizler
      }
    };

    // Yeni soru nesnesi
    const newQuestion = ref({
      text: "",
      type: "open", // Varsayılan olarak açık uçlu
      options: [] as string[],
    });

    // Eklenen sorular listesi
    const questions: any = ref([]);

    // Yeni opsiyon metni
    const newOptionText = ref("");

    // Soru ekleme fonksiyonu
    const addQuestion = () => {
      if (newQuestion.value.text) {
        questions.value.push({
          ...newQuestion.value,
        });
        newQuestion.value = { text: "", type: "open", options: [] };
      }
    };

    // Opsiyon ekleme fonksiyonu
    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    // Opsiyon silme fonksiyonu
    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      questions.value.splice(index, 1);
    };

    const interviews = ref([
      {
        id: 1,
        title: "Teknik Mülakat",
        description: "Bu mülakat adayın teknik bilgilerini ölçer.",
      },
      {
        id: 2,
        title: "Yabancı Dil Mülakatı",
        description: "Bu mülakat adayın yabancı dil seviyesini ölçer.",
      },
      {
        id: 3,
        title: "Yazılım Test Mülakatı",
        description: "Bu mülakat adayın test becerilerini ölçer.",
      },
      {
        id: 4,
        title: "Star Mülakat",
        description:
          "Bu mülakat adayın deneyimlerini ve kariyerine olan bakış açısını ölçer.",
      },
    ]);

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    // Scroll kaymasını engellemek için
    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    onMounted(() => {
      setCurrentPageTitle(t("modals.interview.title"));
    });

    return {
      questionTypeLabel,
      isLoading,
      positionName,
      featureInput,
      features,
      addFeature,
      newQuestion,
      questions,
      newOptionText,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      positionDesc,
      chartOptions,
      series,
      arrowDirection,
      today,
      startDate,
      endDate,
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
    };
  },
  methods: {
    toggleArrow() {
      this.arrowDirection = !this.arrowDirection;
    },
  },
});
